.graticule-black {
  fill: none;
  stroke: #fff;
  stroke-width: 0.8px;
  opacity: 0.9;
}

.loading {
  top: 20%;
  left: 40%;
  position: absolute;
  /* background-color: blue; */
}

.globe {
  fill: #060061;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}
.poster {
  position: relative;
  flex-direction: row;
  display: "block";
  max-width: 60vh;
  margin: 20px;
}
.framed-black {
  border-color: rgb(41, 53, 71);
  border-style: solid;
  border-width: 15px;
  border-image: url(../images/frames/blackframe-port.png) 13 34 26 32 / 1 / 0
    stretch;
}
.framed-wood {
  border-color: rgb(41, 53, 71);
  border-style: solid;
  border-width: 15px;
  border-image: url(../images/frames/woodframe-port.png) 13 34 26 32 / 1 / 0
    stretch;
}
.framed-white {
  border-color: rgb(41, 53, 71);
  border-style: solid;
  border-width: 15px;
  border-image: url(../images/frames/whiteframe-port.png) 13 34 26 32 / 1 / 0
    stretch;
}
.box-shadow {
  box-shadow: 11px -12px 41px -5px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: 11px -12px 41px -5px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 11px -12px 41px -5px rgba(0, 0, 0, 0.38);
}

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  height: 100%;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .wide-console {
    display: none;
  }
  .narrow-console {
    display: block;
  }
  .mainContainer {
    height: auto;
    margin: 10px;
  }
  .makeStyles-root-1 {
    flex-wrap: wrap;
  }
  .framed-black {
    border-color: rgb(41, 53, 71);
    border-style: solid;
    border-width: 10px;
    border-image: url(../images/frames/blackframe-port.png) 13 34 26 32 / 1 / 0
      stretch;
  }
  .framed-wood {
    border-color: rgb(41, 53, 71);
    border-style: solid;
    border-width: 10px;
    border-image: url(../images/frames/woodframe-port.png) 13 34 26 32 / 1 / 0
      stretch;
  }
  .framed-white {
    border-color: rgb(41, 53, 71);
    border-style: solid;
    border-width: 10px;
    border-image: url(../images/frames/whiteframe-port.png) 13 34 26 32 / 1 / 0
      stretch;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .wide-console {
    display: none;
  }
  .narrow-console {
    display: block;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .narrow-console {
    display: none;
  }
  .wide-console {
    display: block;
    height: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */
.stars {
  fill: #fff;
  fill-opacity: 0.9;
}
.constline {
  fill: none;
  stroke: #fff;
  stroke-width: 1px;
}
.overlay {
  fill: #005fc7;
  fill-opacity: 0;
}
